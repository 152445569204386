<template>
  <div>
    <van-nav-bar
      left-text="返回"
      title="邀请好友"
      left-arrow
      @click-left="onClickLeft"
    />
    <section class="fenxiang-scrollView">
      <div class="fenxiang-top-head">
        <div class="fenxiang-title-bg"></div>
        <div class="fenxiang-invite-box">
          <div class="fenxiang-invite-title">
            <h1>邀请好友下单</h1>
            <h2>立享佣金</h2>
            <p>- 新用户每次下单 您都可得佣金返现 -</p>
          </div>
          <!-- <div class="fenxiang-invite-coupon">
            <p>额外<em>6%</em>理财收益特权</p>
          </div> -->
          <div class="fenxiang-button" @click="openDialogVisible">
            <button>邀请好友得现金 多邀多得</button>
          </div>
        </div>
        <div class="fenxiang-flex">
          <div class="fenxiang-flex-box">
            <h2>简单三步 立享权益</h2>
          </div>
        </div>
        <div class="fenxiang-invite-box fenxiang-invite-box-clear">
          <div class="fenxiang-used-layout">
            <div class="fenxiang-grids-item">
              <span class="fenxiang-grids-item-img">1</span>
              <span class="fenxiang-grids-item-text">
                <div>立即分享</div>
                <div>给好友</div>
              </span>
            </div>
            <div class="fenxiang-grids-item">
              <span class="fenxiang-grids-item-img">2</span>
              <span class="fenxiang-grids-item-text">
                <div>好友接受</div>
                <div>注册</div>
              </span>
            </div>
            <div class="fenxiang-grids-item">
              <span class="fenxiang-grids-item-img">3</span>
              <span class="fenxiang-grids-item-text">
                <div>登录购买</div>
                <div>立即返现</div>
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="fenxiang-invite-box fenxiang-invite-box-clear">
          <div class="fenxiang-flex" style="padding: 0">
            <div class="fenxiang-flex-box">
              <h4>每日分享至朋友圈</h4>
              <h5>即得钢镚</h5>
              <div class="fenxiang-button-sml">
                <button>点击分享</button>
              </div>
            </div>
            <div class="fenxiang-gift">
              <img src="images/icon-gift.png" alt="" />
            </div>
          </div>
        </div> -->
        <div class="fenxiang-footer-bg"></div>
      </div>
      <div class="fenxiang-flex">
        <div class="fenxiang-flex-box">
          <h6>我的邀请战绩</h6>
        </div>
      </div>
      <div class="fenxiang-palace">
        <a href="javascript:;" class="fenxiang-palace-grid">
          <div class="fenxiang-palace-grid-icon">
            <span>{{ invitedTotal }}</span>
          </div>
          <div class="fenxiang-palace-grid-text">
            <h2>共邀请好友</h2>
          </div>
        </a>
        <a href="javascript:;" class="fenxiang-palace-grid">
          <div class="fenxiang-palace-grid-icon">
            <span>99+</span>
          </div>
          <div class="fenxiang-palace-grid-text">
            <h2>战绩排名</h2>
          </div>
        </a>
        <a href="javascript:;" class="fenxiang-palace-grid">
          <div class="fenxiang-palace-grid-icon">
            <span style="color: #f97a11">{{ profitTotal }}</span>
          </div>
          <div class="fenxiang-palace-grid-text">
            <h2>累计收益(元)</h2>
          </div>
        </a>
      </div>
      <div class="fenxiang-button-btn" @click="openDialogVisible()">
        <button>继续邀请</button>
      </div>
      <div class="divHeight"></div>
      <!--   <div class="fenxiang-flex">
        <div class="fenxiang-flex-box">
          <h6>今日奖励排行榜</h6>
        </div>
      </div>
      <div class="fenxiang-flex">
        <div class="fenxiang-flex-box">
          <span>奖励排名</span>
        </div>
        <div class="fenxiang-flex-box" style="text-align: right">
          <span>奖励排名</span>
        </div>
      </div>
      <div class="fenxiang-invite-list">
        <a href="javascript:;" class="fenxiang-flex">
          <div class="fenxiang-user-img">
            <img src="@/assets/image/Juicy-1.png" alt="" />
          </div>
          <div class="fenxiang-flex-box">
            <h5>我</h5>
          </div>
          <div class="fenxiang-arrow">
            <p>0.00</p>
          </div>
        </a>
      </div>
      <div class="divHeight"></div>
      <div class="fenxiang-invite-list">
        <a href="javascript:;" class="fenxiang-flex">
          <div class="fenxiang-user-img">
            <img src="@/assets/image/Juicy-1.png" alt="" />
            <span class="fenxiang-vip1"></span>
          </div>
          <div class="fenxiang-flex-box">
            <div>No.1</div>
            <h5>金融小管家</h5>
          </div>
          <div class="fenxiang-arrow">
            <p>190.00</p>
          </div>
        </a>
        <a href="javascript:;" class="fenxiang-flex">
          <div class="fenxiang-user-img">
            <img src="@/assets/image/Juicy-1.png" alt="" />
            <span class="fenxiang-vip2"></span>
          </div>
          <div class="fenxiang-flex-box">
            <div>No.2</div>
            <h5>李晓明同学</h5>
          </div>
          <div class="fenxiang-arrow">
            <p>100.00</p>
          </div>
        </a>
        <a href="javascript:;" class="fenxiang-flex">
          <div class="fenxiang-user-img">
            <img src="@/assets/image/Juicy-1.png" alt="" />
            <span class="fenxiang-vip3"></span>
          </div>
          <div class="fenxiang-flex-box">
            <div>No.3</div>
            <h5>文航</h5>
          </div>
          <div class="fenxiang-arrow">
            <p>90.00</p>
          </div>
        </a>
        <a href="javascript:;" class="fenxiang-flex">
          <div class="fenxiang-user-img">
            <img src="@/assets/image/Juicy-1.png" alt="" />
          </div>
          <div class="fenxiang-flex-box">
            <div>No.4</div>
            <h5>JD_3nfj48</h5>
          </div>
          <div class="fenxiang-arrow">
            <p>50.00</p>
          </div>
        </a>
        <a href="javascript:;" class="fenxiang-flex">
          <div class="fenxiang-user-img">
            <img src="@/assets/image/Juicy-1.png" alt="" />
          </div>
          <div class="fenxiang-flex-box">
            <div>No.5</div>
            <h5>Jd-984jkhs</h5>
          </div>
          <div class="fenxiang-arrow">
            <p>50.00</p>
          </div>
        </a>
      </div> -->
    </section>

    <!-- 弹出分享方式 -->
    <van-popup
      v-model="dialogVisible"
      position="bottom"
      round
      :style="{ height: '30%' }"
    >
      <div style="margin-top: 10px">
        <div class="share-title">邀请好友赚赏金</div>

        <div style="margin-top: 20px">
          <van-grid :column-num="2" :border="false">
            <van-grid-item @click="copyLink">
              <img
                src="@/assets/image/fuzhilianjie.png"
                style="width: 45px; height: 45px"
              />

              <div style="padding-top: 5px">复制链接</div>
            </van-grid-item>

            <van-grid-item @click="imageSharing">
              <img
                src="@/assets/image/fenxiangtupian.png"
                style="width: 45px; height: 45px"
              />

              <div style="padding-top: 5px">分享海报</div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="dialogImageSharingVisible"
      round
      :style="{ height: '90%', width: '90%' }"
    >
      <div style="margin-top: 10px"></div>
    </van-popup>

    <!-- 分享海报组件 -->
    <FenXiangHaiBao ref="HaiBaoComponent"></FenXiangHaiBao>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Toast } from "vant";

import { invitedStatis } from "@/api/user";

import FenXiangHaiBao from "./HaiBao.vue";

export default {
  components: { FenXiangHaiBao },

  data() {
    return {
      //收益详情
      dialogInvitedDetailVisible: false,

      //图片分享
      dialogImageSharingVisible: false,

      //分享弹框
      dialogVisible: false,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        inviterLevel: 1,
      },
      invitedTotal: undefined,
      profitTotal: undefined,

      invitedList: [],
      total: 0,
    };
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      invited_list: (state) => state.user.invited_list,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
    }),
  },
  created() {
    //加载用户信息
    this.$store.dispatch("user/info");
    // this.loadData();

    //查询收益统计
    this.queryInvitedStatis();
  },
  methods: {
    /**
     * 查询收益统计
     */
    queryInvitedStatis() {
      var data = {};
      new Promise((resolve, rejust) => {
        invitedStatis(data)
          .then((res) => {
            this.invitedTotal = res.result.invitedTotal;
            this.profitTotal = res.result.profitTotal;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 复制链接
     */
    copyLink() {
      const input = document.createElement("input");
      input.value = this.inviteUrl; // 设置要复制的文本
      document.body.appendChild(input);
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行复制操作
      document.body.removeChild(input); // 移除临时的输入框
      Toast.success({
        message: "链接已复制到剪贴板",
      });
    },

    /**
     * 图片分享
     */
    imageSharing() {
      // Toast({
      //   message: "很快就会推出。敬请期待!",
      // });
      this.$refs.HaiBaoComponent.SubmitOpen();
    },

    /**
     * 打开弹出框
     */
    openDialogVisible() {
      this.dialogVisible = true;
    },

    /**
     * 返回上一页
     */
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.fenxiang-scrollView {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  background-color: #ffffff;
}

.icon {
  width: 20px;
  height: 20px;
  display: block;
  border: none;
  float: left;
  background-size: 20px;
  background-repeat: no-repeat;
}

.fenxiang-top-head {
  background: #fad477;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.fenxiang-title-bg {
  width: 100%;
  height: 10rem;
  position: relative;
  /* background-image: url("../images/title-bg.png"); */

  background-image: url("../../assets/image/title-bg.png");

  background-size: 100%;
}

.fenxiang-title-bg {
  background-repeat: no-repeat;
}

.fenxiang-invite-box {
  width: 90.333%;
  height: auto;
  border-radius: 4px;
  background: #fff;
  padding: 20px;
  margin: -100px auto 0;
}

.fenxiang-invite-title {
  background-repeat: no-repeat;
}

.fenxiang-invite-title {
  width: 100%;
  background-image: url("../../assets/image/head-bg.png");
  background-size: 100%;
  text-align: center;
  padding: 30px 0;
  margin-top: 1rem;
}

.fenxiang-invite-title h1 {
  color: #fff;
  font-size: 1.8rem;
  position: relative;
}

.fenxiang-invite-title h1:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -5px;
  left: 60px;
  width: 60%;
  height: 1px;
  border-bottom: 1px solid #ffaf7a;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.fenxiang-invite-title h2 {
  color: #fff;
  font-size: 3rem;
  margin-bottom: 0.8rem;
}

.fenxiang-invite-title h2 em {
  font-style: normal;
  background: linear-gradient(to bottom, #ffe700, #feeda7);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 3.5rem;
}

.fenxiang-invite-title h2 i {
  font-style: normal;
  font-size: 1.4rem;
}

.fenxiang-invite-title p {
  color: #feeed5;
  font-size: 0.85rem;
}

.fenxiang-invite-coupon {
  background-repeat: no-repeat;
}

.fenxiang-invite-coupon {
  margin-top: 1rem;
  width: 100%;
  background-image: url("../../assets/image/coupon.png");
  background-size: 100%;
  text-align: center;
  color: #b97d3e;
  font-size: 0.95rem;
  height: 4rem;
  line-height: 3.5rem;
  padding-left: 3rem;
}

.fenxiang-invite-coupon em {
  color: #ff7700;
  font-style: normal;
}

.fenxiang-button {
  margin-top: 1rem;

  background-image: url("../../assets/image/button-large.png");
  background-size: 100%;
  width: 100%;
  height: 4rem;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
}

.fenxiang-button button {
  background-size: 100%;
  width: 100%;
  height: 3.8rem;
  border: none;
  background: none;
  border-radius: 30px;
  color: #fff;
  box-shadow: 0 8px 10px #ffaba6;
}

.fenxiang-flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
}

.fenxiang-flex-box h2 {
  text-align: center;
  font-size: 1rem;
  color: #ad6a26;
  position: relative;
}

.fenxiang-flex-box h2:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 15%;
  width: 30px;
  height: 17px;
  /* background-image: url("../images/icon-tag.png"); */
  background-image: url("../../assets/image/icon-tag.png");

  background-size: 30px;
}

.fenxiang-flex-box h2:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 15%;
  width: 30px;
  height: 17px;
  background-image: url("../../assets/image/icon-tag.png");
  background-size: 30px;
}

.fenxiang-invite-box-clear {
  margin-top: 0;
  margin-bottom: 1rem;
}

.fenxiang-used-layout {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.fenxiang-grids-item {
  position: relative;
  float: left;
  padding: 10px 10px 12px 10px;
  width: 33.333%;
  box-sizing: border-box;
}

/* .fenxiang-grids-item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 61px;
  left: 43px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #eee7dd;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
} */

.fenxiang-grids-item-img {
  text-align: center;
  width: 22px;
  height: 22px;
  line-height: 20px;
  display: block;
  color: #fff;
  font-size: 0.95rem;
  background: #bc7e45;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}

.fenxiang-grids-item-text {
  text-align: center;
  width: 100%;
  display: block;
  color: #b47f4d;
  font-size: 14px;
  padding-top: 8px;
}

.fenxiang-gift {
  width: 90px;
  height: 90px;
}

.fenxiang-gift img {
  width: 100%;
  height: auto;
  display: block;
  border: none;
}

.fenxiang-button-sml {
  background-repeat: no-repeat;
}

.fenxiang-button-sml {
  margin-top: 1rem;
  /* background-image: url(../images/button-small.png); */
  background-size: 80%;
  width: 80%;
  height: 2.8rem;
  border: none;
  border-radius: 40px;
}

.fenxiang-button-sml button {
  background-size: 100%;
  width: 80%;
  height: 2.8rem;
  border: none;
  background: none;
  border-radius: 30px;
  color: #fff;
  box-shadow: 0 8px 10px #ffaba6;
}

.fenxiang-flex-box h4 {
  color: #ba7f47;
}

.fenxiang-flex-box h5 {
  color: #fc7701;
  font-size: 1rem;
}

.fenxiang-footer-bg {
  background-image: url("../../assets/image/footer-bg.png");
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 5rem; */
}

.fenxiang-flex-box h6 {
  color: #454545;
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
  position: relative;
}

.fenxiang-flex-box h6:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -10px;
  left: 50%;
  width: 16%;
  margin-left: -8%;
  height: 5px;
  background: #eeecec;
  border-radius: 50px;
}

.fenxiang-flex-box span {
  color: #969696;
  font-size: 0.8rem;
}

.fenxiang-palace {
  padding: 0.9rem 0;
  position: relative;
  overflow: hidden;
}

.fenxiang-palace-grid {
  position: relative;
  float: left;
  padding: 1px;
  width: 33.333%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.fenxiang-palace-grid-icon {
  margin: 0 auto;
  text-align: center;
}

.fenxiang-palace-grid-icon span {
  color: #424242;
  font-size: 1.3rem;
}

.fenxiang-palace-grid-text {
  display: block;
  text-align: center;
  color: #999999;
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.2rem;
}

.fenxiang-palace-grid-text h2 {
  font-size: 0.8rem;
  font-weight: normal;
  color: #999999;
}

.fenxiang-button-btn {
  padding: 0 20px 20px 20px;
}

.fenxiang-button-btn button {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  color: #fff;
  background: #f14a28;
  border: none;
  box-shadow: 0 8px 19px #fcbdab;
}

.fenxiang-user-img {
  width: 45px;
  height: 50px;
  margin-right: 0.5rem;
  position: relative;
}

.fenxiang-vip1 {
  position: absolute;
  top: -8px;
  left: -2px;
  background-image: url("../../assets/image/icon-vip1.png");
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.fenxiang-user-img img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: block;
  border: none;
}

.fenxiang-arrow {
  color: #fc7701;
  font-size: 1rem;
}

.fenxiang-flex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px;
  position: relative;
}

.fenxiang-invite-list .fenxiang-flex-box h4 {
  color: #464646;
  font-weight: normal;
}

.fenxiang-invite-list .fenxiang-flex-box h5 {
  color: #969696;
  font-size: 0.8rem;
  font-weight: normal;
}

.fenxiang-invite-list .fenxiang-flex-box .fenxiang-no1 {
  background: #fdb843;
  border-radius: 3px;
  color: #fff;
  font-style: initial;
  width: 4rem;
  text-align: center;
  padding: 1px 0;
  -webkit-transform: skew(-10deg);
}

.fenxiang-invite-list .fenxiang-flex-box .fenxiang-no2 {
  background: #b2b9bf;
  border-radius: 3px;
  color: #fff;
  font-style: initial;
  width: 2.5rem;
  text-align: center;
  padding: 0.2rem 0;
  -webkit-transform: skew(-10deg);
}

.fenxiang-invite-list .fenxiang-flex-box .fenxiang-no3 {
  background: #c88a73;
  border-radius: 3px;
  color: #fff;
  font-style: initial;
  width: 2.5rem;
  text-align: center;
  padding: 0.2rem 0;
  -webkit-transform: skew(-10deg);
}

.divHeight {
  width: 100%;
  height: 10px;
  background: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.share-title {
  font-size: 18px;
  font-weight: 700;
  color: #0e0d0d;
  text-align: center;
  padding: 10px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
</style>
