<template>
  <div>
    <van-nav-bar
      title="资金明细"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div>
      <div class="use-money-detail">
        <div class="scroll-box">
          <div>
            <div style="overflow: hidden auto">
              <div>
                <!---->
                <van-empty
                  description="暂无资金消费~"
                  v-if="tableData.length <= 0"
                />

                <div class="money_detail_content" v-else>
                  <div
                    class="item"
                    v-for="item in tableData"
                    :key="item.id"
                    :value="item.amount"
                    @click="openMoneyDetails(item)"
                  >
                    <div class="left">
                      <span class="title"> {{ item.eventText }} </span>
                      <span class="amountDetail_time">
                        {{ item.datelineReadable }}
                      </span>
                    </div>
                    <div class="right">{{ item.amount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="账单"
      :destroy-on-colse="true"
      :visible.sync="detailVisible"
      append-to-body
      width="90%"
      class="el_dialog_radius"
    >
      <div>
        <div style="text-align: center">
          <img
            class="avatarUrl"
            src="@/assets/zhangdan.png"
            draggable="false"
            style="text-align: center"
          />
        </div>

        <!-- 金额 -->
        <div
          style="
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            color: #000000;
            margin-top: 15px;
          "
        >
          {{ moneyDetails.amount }}
        </div>

        <!-- 类型 -->
        <div style="text-align: center; margin-top: 10px">
          {{ moneyDetails.eventText }}
        </div>

        <div class="amount_detail_info" style="margin-top: 40px">
          <div class="item">
            <div class="label">订单编号：</div>
            <div class="item-value">{{ moneyDetails.serialNum }}</div>
          </div>

          <div class="item">
            <div class="label">交易类型：</div>
            <div class="item-value">{{ moneyDetails.eventText }}</div>
          </div>

          <div class="item">
            <div class="label">交易金额：</div>
            <div class="item-value">{{ moneyDetails.amount }}</div>
          </div>

          <div class="item">
            <div class="label">交易时间：</div>
            <div class="item-value">{{ moneyDetails.datelineReadable }}</div>
          </div>

          <div class="item">
            <div class="label">交易备注：</div>
            <div class="item-value">{{ moneyDetails.remarks }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      detailVisible: false,
      moneyDetails: {},
      activeNames: ["1"],

      tableData: [],
      loadingState: false,
      finished: false,
      queryParam: {
        // pageNum: 1,
        // pageSize: 10,
      },
    };
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      statusText: (state) => state.user.statusText,
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("balance/list", this.queryParam).then(() => {
        this.tableData = this.tableData.concat(this.tableDatas);
      });
    },

    /**
     * 获取金额明细详情
     */
    openMoneyDetails(item) {
      this.moneyDetails = item;
      this.detailVisible = true;
    },

    //跳转到我的页面
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
.use-money-detail {
  /* width: 23.4375rem; */
  height: 100vh;
  box-sizing: border-box;
  padding-top: 0.9375rem;
}

.use-money-detail .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.125rem;
  width: 100%;
  padding: 0 1.1875rem;
  box-sizing: border-box;
}

.use-money-detail .header .search-input {
  box-sizing: border-box;
  width: 16.875rem;
  height: 2.125rem;
  background: #ffffff;
  border-radius: 1.0625rem;
  padding: 0 0.84375rem;
}

.use-money-detail .scroll-box {
  width: 23.4375rem;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 70px);
}

.use-money-detail .scroll-box .money_detail_content {
  padding: 0 0.5625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.625rem;
}

.use-money-detail .scroll-box .money_detail_content .item {
  display: flex;
  width: 100%;
  height: 4.375rem;
  background: #ffffff;
  border-radius: 0.46875rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.90625rem;
}

.use-money-detail .scroll-box .money_detail_content .item .left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.375rem;
}

.use-money-detail .scroll-box .money_detail_content .item .left .title {
  color: #1e2331;
  font-weight: 500;
  font-size: 0.9375rem;
}

.use-money-detail
  .scroll-box
  .money_detail_content
  .item
  .left
  .amountDetail_time {
  color: #c4c7cf;
  font-weight: 400;
  font-size: 0.75rem;
}

.use-money-detail .scroll-box .money_detail_content .item .right {
  font-weight: 500;
  font-size: 1.25rem;
  color: #1e2331;
}

.use-money-detail .poput-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.use-money-detail .poput-form .form-item {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.03125rem solid #f5f7faff;
  font-weight: 400;
  color: #1e2331;
  font-size: 0.9375rem;
}

.use-money-detail .poput-form .form-item:active {
  background-color: #c4c7cf;
}

/* 详情 */
.amount_detail_info {
  margin: 10px 10px 30px 10px;
}
.amount_detail_info .item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.amount_detail_info .item .label {
  color: #9b9fa8;
  font-size: 0.875rem;
}

.amount_detail_info .item .item-value {
  color: #1e2331;
  margin-left: 20px;
}

.avatarUrl {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
